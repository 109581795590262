
<div *ngIf="loading || loadingPaymentType" class="loading">
    <mat-spinner [diameter]="50" class="spinner"></mat-spinner>
</div>

<span *ngIf="!loading && !loadingPaymentType">
    <div mat-dialog-title class="header">
        <button
            mat-mini-fab
            mat-dialog-close
            type="button"
            class="closeModal"
        >
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div *ngIf="data.search">
            <div class="procedureName col-12">
                <h2>
                {{ data.search.procedure.name }}
                </h2>
            </div>
            <app-generate-payment-link
                [uuid]=""
                [scheduleId]="data.scheduleId"
            >
            </app-generate-payment-link>
            <div class="professinalAndValue">
                <div class="professionalDocument col-xl-5 col-lg-5 col-md-5 col-sm-12">
                    <h3 *ngIf="data.search.treatmentPronoun && data.search.professional">{{ data.search.treatmentPronoun }} {{ data.search.professional }}</h3>
                    <p *ngIf="data.search.occupation && data.search.expertise">
                        {{ data.search.occupation.name }}
                        {{ data.search.expertise.name ? '(' + data.search.expertise.name + ')' : '' }}
                    </p>
                    <h4 class="purple" *ngIf="data.search.document">
                        {{ data.search.documentType }} {{ data.search.document }}
                        <span *ngIf="data.search.rqe">RQE {{ data.search.rqe }}</span>
                    </h4>
                </div>
                <div class="valueToPay col-xl-5 col-lg-5 col-md-5 col-sm-12">
                    <h2 class="purple">R$ {{data.search.price.replace('.', ',')}}</h2>
                </div>
            </div>

        </div>
        <span>
            <h3>Selecione a forma de pagamento</h3>

            <mat-radio-group
                arial-label="Selecione a forma de pagamento"
                [(ngModel)]="paymentMethod"
                class="row"
                (change)="onChangePaymentMethod()"
                >
                <mat-radio-button *ngIf="paymentType.credit" value="credit" class="radioBtn col-xl-5 col-lg-5 col-md-5 col-sm-12"><fa-icon icon="credit-card"></fa-icon> Cartão de crédito</mat-radio-button>
                <mat-radio-button *ngIf="paymentType.pix" value="pix" class="radioBtn col-xl-5 col-lg-5 col-md-5 col-sm-12">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="pixIcon"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z"/></svg>
                     Pix
                </mat-radio-button>
            </mat-radio-group>
            <p class="usageRules">Ao pagar, declaro que sou maior de idade e aceito as <button mat-button (click)="showUsageRules()">Regras de utilização</button></p>
            <container [ngSwitch]="paymentMethod">
                <div *ngSwitchCase="'credit'">
                    <div *ngIf="hasCardToken.cardNumber" class="useCard">
                        <p>Deseja utilizar o cartão, <b>{{hasCardToken.cardNumber}}</b>, usado anteriormente?</p>
                        <ng-container *ngIf="!settingInstallment">
                            <button
                                mat-button
                                class="btn-secondary yesBtn"
                                (click)="toUseSameCard(useSameCard)"
                            >
                                Sim
                            </button>
                            <button
                                mat-button
                                class="btn-secondary"
                                (click)="toUseSameCard(!useSameCard)"
                            >
                                Não
                            </button>
                        </ng-container>
                        <form [formGroup]="fg" *ngIf="settingInstallment">
                            <mat-form-field appearance="outline" class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                <mat-label>Parcelas</mat-label>
                                <mat-spinner 
                                    [diameter]="20" 
                                    color="primary"
                                    class="selectionSpinner"
                                    *ngIf="loadingInstallments; else selection"
                                >
                                    <mat-select formControlName="installment"></mat-select>
                                </mat-spinner>
                                <ng-template #selection>
                                    <mat-select
                                        formControlName="installment"
                                    >
                                        <mat-option *ngFor="let installment of installments" [value]="installment">
                                          {{installment.quantity + ' x de ' + 'R$ ' + installment.value.replace('.', ',')}}
                                        </mat-option>
                                    </mat-select>
                                </ng-template>
                            </mat-form-field>
                            <button
                                mat-button
                                class="btn-secondary"
                                (click)="toUseSameCard(useSameCard)"
                            >
                                Efetuar Pagamento
                            </button>
                        </form>
                    </div>
                    <form *ngIf="!hasCardToken.cardNumber" [formGroup]="fg">
                        <div class="row">
                        <div class="col-12 loginForm">
                            <mat-form-field appearance="outline" class="col-12">
                            <mat-label>Nome do titular</mat-label>
                            <input
                                matInput
                                type="text"
                                formControlName="name"
                                oninput="this.value = this.value.toUpperCase()"
                            />
                            </mat-form-field>
                
                            <mat-form-field appearance="outline" class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                            <mat-label>Número do cartão</mat-label>
                            <input
                                matInput
                                type="text"
                                formControlName="cardNumber"
                                maxlength="19"
                                (keypress)="onlyNumbers($event)"
                                (input)="setSpaceCreditCard($event)"
                                (change)="validateCardFlag($event)"
                            />
                            <mat-error *ngIf="fg.controls.cardNumber.hasError('invalid')">
                                <strong>Número de cartão inválido</strong>
                            </mat-error>
                            <mat-error *ngIf="fg.controls.cardNumber.hasError('invalidFlag')">
                                <strong>Bandeira {{cardFlag}} não aceita para pagamentos</strong>
                            </mat-error>
                            </mat-form-field>
                
                            <mat-form-field appearance="outline" class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                            <mat-label>Vencimento</mat-label>
                            <input
                                matInput
                                type="text"
                                formControlName="dueDate"
                                (input)="setDivisionDueDate($event)"
                                maxlength="5"
                            />
                            </mat-form-field>
                
                            <mat-form-field appearance="outline" class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                                <mat-label>CVV</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    (keypress)="onlyNumbers($event)"
                                    formControlName="cvv"
                                    maxlength="4"
                                />
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                <mat-label>Parcelas</mat-label>
                                <mat-spinner 
                                    [diameter]="20" 
                                    color="primary"
                                    class="selectionSpinner"
                                    *ngIf="loadingInstallments; else selection"
                                >
                                    <mat-select formControlName="installment"></mat-select>
                                </mat-spinner>
                                <ng-template #selection>
                                    <mat-select
                                        formControlName="installment"
                                    >
                                        <mat-option *ngFor="let installment of installments" [value]="installment">
                                          {{installment.quantity + ' x de ' + 'R$ ' + installment.value.replace('.', ',')}}
                                        </mat-option>
                                    </mat-select>
                                </ng-template>
                            </mat-form-field>
                        </div>
                        </div>
                    </form>
                </div>
                <div *ngSwitchCase="'pix'">
                    <div class="qrcodeImage">
                        <qrcode
                          [qrdata]="pixQRCodeValue"
                          [allowEmptyString]="true"
                          [ariaLabel]="'QR Code image with pix payment content...'"
                          [cssClass]="'center'"
                          [colorDark]="'#7b68ee'"
                          [colorLight]="'#ffffff'"
                          [elementType]="'canvas'"
                          [errorCorrectionLevel]="'M'"
                          [margin]="4"
                          [scale]="1"
                          [title]="'Pagamento por pix'"
                          [width]="200"
                        ></qrcode>
                    </div>
                    <p style="text-align: center; margin: 0;"><b>OBS: O nome que aparecerá no PIX será da Zoop Meios de Pagamento</b></p>
                    <p style="text-align: center; margin: 0;"><b>Ao realizar o pagamento, aguarde, pois a confirmação pode demorar uns instantes</b></p>
                    <div class="pixArea">
                        <mat-form-field appearance="outline">
                            <mat-label>Código Pix</mat-label>
                            <input
                            matInput
                            type="text"
                            [formControl]="pixCode"
                            />
                        </mat-form-field>
                        <button
                            mat-button
                            class="copyCodeBtn copyCodeBtn-defaultColor"
                            matTooltip="Copiar código"
                            matTooltipPosition="above"
                            (click)="copyCodeToClipboard()"
                        >
                            <fa-icon icon="copy"></fa-icon>
                        </button>
                    </div>
                    <p>Leia o QRCode ou copie o código acima através do botão e vá até o aplicativo do seu banco para efeutar o pagamento na área PIX.</p>
                    <p>Assim que finalizar o pagamento, volte para essa tela, e fecha a janela no botão X vermelho acima, para finalizamos o agendamento</p>

                </div>
                <div *ngSwitchDefault></div>
                <div class="action" *ngIf="paymentMethod === 'credit'">
                    <button 
                        *ngIf="!hasCardToken.cardNumber"
                        mat-button
                        class="btn-secondary"
                        (click)="pay()"
                    >
                        Efetuar Pagamento
                    </button>
                </div>
            </container>
        </span>
        <div *ngIf="loading" class="loadingPayment">
            <mat-spinner [diameter]="50" class="spinner"></mat-spinner>
        </div>
    </div>
</span>
